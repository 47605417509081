.btn-home {
  color: #ffffff;
  background-color: #363636;
  border-color: #363636;
}

.btn-home:hover,
.btn-home:focus,
.btn-home:active,
.btn-home.active,
.open .dropdown-toggle.btn-home {
  color: #ffffff;
  background-color: #272525;
  border-color: #363636;
}

.btn-home:active,
.btn-home.active,
.open .dropdown-toggle.btn-home {
  background-image: none;
}

.btn-home.disabled,
.btn-home[disabled],
fieldset[disabled] .btn-home,
.btn-home.disabled:hover,
.btn-home[disabled]:hover,
fieldset[disabled] .btn-home:hover,
.btn-home.disabled:focus,
.btn-home[disabled]:focus,
fieldset[disabled] .btn-home:focus,
.btn-home.disabled:active,
.btn-home[disabled]:active,
fieldset[disabled] .btn-home:active,
.btn-home.disabled.active,
.btn-home[disabled].active,
fieldset[disabled] .btn-home.active {
  background-color: #363636;
  border-color: #2a84eb;
}

.btn-home .badge {
  color: #363636;
  background-color: #ffffff;
}
